<script setup>
import { ref } from "vue";
import { api } from "@/services";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { resetErrors, setErrors, validate } from "@/user-account/utils/input-rules/validation";
import PersonalInfoForm from "@/user-account/components/auth/PersonalInfoForm.vue";
import { toast } from "@/app/utils/notification";
import { useBetStore } from "/store";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import { t } from "@/app/composables/useI18n";

const { user } = useUserService();
const regions = ref([]);
const personalData = ref({
  email: null,
  firstName: null,
  lastName: null,
  dateOfBirth: null,
});

const store = useBetStore();

async function loadData() {
  const [data, regionsList] = await Promise.all([
    api.users.getPersonalData(),
    api.utils.listRegions({ countryCode: "NG" }),
  ]);

  personalData.value = data;
  regions.value = regionsList;
}

async function onPersonalInfoSubmit(form) {
  if (!validate(form)) {
    return;
  }

  try {
    await api.users.storePersonalData({
      email: form[0].value,
      firstName: form[1].value,
      lastName: form[2].value,
      dateOfBirth: form[3].value,
    });

    user.value.kycLevel = 1;

    resetErrors(form);

    toast.info("Your personal data was updated!");

    store.setSection("wallet");
    store.setWalletTab("deposit");
  } catch (error) {
    setErrors(form, error.errors);
    toast.error("Unable to update personal data");
  }
}

loadData();
</script>

<template>
  <div class="kyc">
    <div class="form">
      <PersonalInfoForm
        :regions="regions"
        :personal-data="personalData"
        @submit="onPersonalInfoSubmit"
      />
    </div>
  </div>
  <!-- Temporary disabled -->
  <!-- <div class="form" v-if="user?.kycLevel === 1 || user?.kycWithdrawalBlocked">
    <BaseButton class="w100" @click="api.kyc.redirect()">
      {{ t("kyc.verify_identity") }}
    </BaseButton>
  </div> -->
</template>

<style lang="scss" scoped>
.margin-top {
  margin-top: 15px;
}

.country-select {
  background-color: var(--be-small-card-bg);
  height: 48px;
  width: 100%;
  padding-top: 7px;
}

.phone {
  margin: 16px 0;
}

.country {
  display: flex;
  gap: 0 20px;
  margin: 16px 0;
}

.postal-code {
  margin-top: 16px;
}

.label {
  font-size: 14px;
  margin-bottom: 7px;
}

.kyc {
}

@media screen and (max-width: 500px) {
  .kyc {
    height: 100%;
  }
}

.form {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
  margin-bottom: 1rem;
}

.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 20px 0px;
  font-size: 14px;
}
.kyc-title {
  font-size: 12px;
  line-height: normal;
}
.levels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 10px;
  margin: 16px 0px;
  .level {
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.3);
    width: 132px;
    text-transform: uppercase;
  }

  .level-active {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
    box-shadow: 0px 0px 10px 0px rgba(116, 62, 232, 0.5);

    height: 32px;
    color: white;
  }

  .form {
    border-radius: 8px;
    background: var(--be-big-card-bg);
    margin: 16px;
    padding: 16px;
  }
}

.w100 {
  width: 100%;
}
</style>
