import { ref } from "vue";

const geoblocked = ref(false);
const systemSettings = ref(false);
const isDrawerOpen = ref(true);
const appDivisions = ref([]);
const appDivision = ref();

export function useConfig() {
  function setSystemSettings(_systemSettings) {
    systemSettings.value = _systemSettings;
    appDivisions.value = ((_systemSettings.divisions as any[]) ?? [])
      .filter((division) => division.active)
      .map((division) => {
        return division.name;
        // return division.name == "sportsbook"
        //   ? "Sports"
        //   : division.name == "virtualGames"
        //   ? "Virtuals"
        //   : division.name == "casino"
        //   ? "Casino"
        //   : division.name;
      });

    [appDivisions.value[0], appDivisions.value[1]] = [appDivisions.value[1], appDivisions.value[0]];
  }

  return {
    appDivision,
    appDivisions,
    isDrawerOpen,
    geoblocked,
    systemSettings,
    setSystemSettings,
  };
}
