<script setup>
import { computed, ref } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import BasePhoneField from "@/app/components/ui/BasePhoneField.vue";
import { rules as phoneRules } from "@/user-account/utils/input-rules/phone";
import { rules as passwordRules } from "@/user-account/utils/input-rules/password";
import { api } from "@/services";

const emit = defineEmits(["submit"]);

const { t } = useI18n();

const countries = ref([]);

const inputs = ref([
  {
    name: "countryCode",
    value: "",
    type: "text",
  },
  {
    name: "phone",
    value: "",
    type: "tel",
    label: t("auth.phone"),
    placeholder: t("auth.phone"),
    rules: phoneRules,
    error: "",
  },
  {
    name: "password",
    value: "",
    type: "password",
    label: "New Password",
    placeholder: "New Password",
    rules: passwordRules,
    error: "",
    iconName: "eye-opened",
  },
]);

const countryCodes = computed(() => {
  return countries.value.data?.filter((country) => country.status).map((country) => country.code);
});

async function fetchCountries() {
  countries.value = await api.utils.listCountries({
    paginator: {
      cp: 1,
      rpp: 1000,
    },
  });
}

fetchCountries();
</script>

<template>
  <form @submit.prevent="$emit('submit', inputs)">
    <div class="form-fields">
      <BasePhoneField
        :model-value="inputs[1].value"
        :rules="inputs[1].rules"
        :label="inputs[1].label"
        :placeholder="inputs[1].placeholder"
        :type="inputs[1].type"
        :icon-name="inputs[1].iconName"
        :error="inputs[1].error"
        :country-codes="countryCodes"
        @update:model-value="inputs[1].value = $event"
        @country-changed="inputs[0].value = $event.iso2"
        @error="inputs[1].error = $event"
      />
      <BaseTextField
        :model-value="inputs[2].value"
        :rules="inputs[2].rules"
        :label="inputs[2].label"
        :placeholder="inputs[2].placeholder"
        :type="inputs[2].type"
        :icon-name="inputs[2].iconName"
        :error="inputs[2].error"
        @update:model-value="inputs[2].value = $event"
        @error="inputs[2].error = $event"
      />
    </div>

    <BaseButton class="alight wide high" type="submit"> Reset Password </BaseButton>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.rest-button {
  text-align: center;
  color: #3886ec;
  font-size: 14px;
  width: 100%;
}
.rest-prompt {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}
</style>
