function cfl(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const ellipsis = function (value, limit) {
  value = String(value);
  if (typeof value === "string" && value.length > limit) {
    value = `${value.slice(0, limit)}...`;
  }

  return value;
};

export { cfl, ellipsis };
