export function isAgeValid(dob) {
  if (Number.isNaN(new Date(dob))) {
    return "Invalid Date";
  };

  const age = (new Date() - new Date(dob)) / (365 * 24 * 60 * 60 * 1000);

  return age >= 18 || "You must be 18 or older";
}

export const rules = [isAgeValid];
