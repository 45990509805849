<script setup>
import { ref } from "vue";

const props = defineProps({
  value: String,
  style: String,
});

const menuOpen = ref(false);
</script>

<template>
  <div class="select" @click="menuOpen = !menuOpen">
    <div class="select-item">
      <div>{{ value }}</div>
      <img
        :src="`/assets/images/${menuOpen ? 'up' : 'down'}-arrow.svg`"
        style="width: 12px"
        alt=""
      />
    </div>

    <div v-if="menuOpen" class="items">
      <slot name="header" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.select {
  position: relative;
  height: 100%;
}
.select-item {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  background: var(--be-small-card-bg);
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;
}

.items {
  border-radius: 8px;
  background: var(--be-bg);
  padding: 8px;
  background: var(--be-small-card-bg);
  margin-top: 10px;
  width: 100%;
  position: absolute;
  z-index: 1;
}
</style>
