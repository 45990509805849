<script setup>
import { ref } from "vue";
import { api } from "@/services";
import LoginInfoForm from "@/user-account/components/auth/LoginInfoForm.vue";
import { useUserService } from "@/user-account/composables/useUserService.ts";
import { resetErrors, setErrors, validate } from "@/user-account/utils/input-rules/validation";
import { toast } from "@/app/utils/notification";
import { changePassword } from "../../../../user-account/api/index";

const { user } = useUserService();

const loginInfo = ref({
  phone: `+${user.value.phone}`,
  currentPassword: null,
  newPassword: null,
  confirmPassword: null,
});

async function onSubmit(form) {
  if (!validate(form)) {
    return;
  }

  try {
    const { data: changePasswordData } = await changePassword({
      currentPassword: form[1].value,
      newPassword: form[2].value,
      confirmPassword: form[3].value,
    });

    resetErrors(form);

    loginInfo.value = {
      phone: `+${user.value.phone}`,
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
    };

    toast.info("Your password was updated!");
  } catch (error) {
    const { errors } = error || {};
    setErrors(form, errors);
    toast.error("Unable to update password");
  }

  // try {
  //   await api.auth.changePassword({
  //     currentPassword: form[1].value,
  //     newPassword: form[2].value,
  //     confirmPassword: form[3].value,
  //   });

  //   resetErrors(form);

  //   loginInfo.value = {
  //     phone: `+${user.value.phone}`,
  //     currentPassword: null,
  //     newPassword: null,
  //     confirmPassword: null,
  //   };

  //   toast.info("Your password was updated!");
  // } catch (error) {
  //   setErrors(form, error.errors);
  //   toast.error("Unable to update password");
  // }
}
</script>

<template>
  <div class="edit-info">
    <LoginInfoForm :login-info="loginInfo" @submit="onSubmit" />
  </div>
</template>

<style lang="scss" scoped>
.password {
  margin: 16px 0;
}

.email {
  margin: 8px 0 16px 0px;
}
.label {
  font-size: 14px;
  margin-bottom: 7px;
}
.btn-g {
  color: var(--be-primary-btn-label);
  border: none;
  height: 42px;
  width: 100%;
  border-radius: 4px;
  background: var(--Linear, linear-gradient(90deg, #3886ec 0%, #544fe5 100%));
  margin: 12px 0px;
  font-size: 14px;
}

.edit-info {
  border-radius: 8px;
  background: var(--be-big-card-bg);
  padding: 16px;
  margin-top: 10px;
  margin-bottom: 1rem;
}
</style>
