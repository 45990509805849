<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";
import { useBetStore } from "../../../store";
import { useUserService } from "@/user-account/composables/useUserService";
import { useGameHelper } from "@/casino/composables/useGameHelper";
import { useFreeBets } from "@/sportsbook/composables/useFreeBets";
import { useCurrencies } from "@/app/composables/useCurrencies";
import { t } from "@/app/composables/useI18n";
import moment from "moment";

const screenWidth = ref(window.innerWidth);

const { freeBets } = useFreeBets();
const { getIconByTicker } = useCurrencies();

function handleResize() {
  screenWidth.value = window.innerWidth;
}

const store = useBetStore();
const { isGameSession } = useGameHelper();

onMounted(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Initial check
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const { wallets, activeWallet, user, selectWallet } = useUserService();

const menuOpen = ref(false);

function handleShowModalWallet() {
  if (store.betModal && store.section === "wallet" && store.getWalletTab == "balance") {
    store.handleShowBetModal();
  } else if (store.section == "wallet" && store.getWalletTab == "deposit") {
    store.setWalletTab("balance");
  } else if (store.betModal && store.section !== "wallet") {
    store.setSection("wallet");
    store.setWalletTab("balance");
  } else {
    store.handleShowBetModal();
    store.setSection("wallet");
    store.setWalletTab("balance");
  }
}

function handleShowModal() {
  if (store.betModal && store.section === "account") {
    store.handleShowBetModal();
  } else if (store.betModal && store.section !== "account") {
    store.setSection("account");
  } else {
    store.handleShowBetModal();
    store.setSection("account");
  }
}

function handleShowMenu() {
  menuOpen.value = !menuOpen.value;
  store.handleShowBetModal(false);
}

function setActiveWallet(wallet) {
  if (isGameSession.value) {
    return;
  }

  selectWallet(wallet.id);
  menuOpen.value = false;
}
</script>

<template>
  <div class="wallet-container">
    <div class="user-wallet-section">
      <div v-if="screenWidth >= 568" class="user-section" @click="handleShowModal">
        <img src="/assets/images/user-icon.svg" alt="" />
      </div>
      <div
        class="user-balance-section"
        :class="{ 'left-rounded': screenWidth < 568 }"
        @click="handleShowModalWallet"
      >
        <div class="user-name" :class="{ active: menuOpen }">+{{ user?.phone }}</div>
        <div class="user-balance" :class="{ active: menuOpen }">
          <div style="display: flex; align-items: center">
            <div>
              {{ Number(activeWallet?.balance)?.toFixed(activeWallet?.currency?.places) }}
            </div>
            <img
              :src="getIconByTicker(activeWallet?.currency?.ticker)"
              class="currency-icon"
              alt=""
            />
          </div>
          <img src="/assets/images/down-arrow.svg" alt="" />
        </div>
      </div>
      <!-- <div class="wallet-section" @click="handleShowModalWallet">
        <img src="/assets/images/bets/wallet.svg" alt="" />
      </div> -->
    </div>

    <div v-if="menuOpen" class="user_wallet-drop-down">
      <div
        v-for="wallet in wallets"
        :key="wallet.id"
        class="wallet-drop-item"
        :class="{ 'item-active': activeWallet.id === wallet.id }"
        @click="() => setActiveWallet(wallet)"
      >
        <div>
          <img v-if="activeWallet.id === wallet.id" src="/assets/images/selected.svg" alt="" />
        </div>

        <div class="item-right">
          <div>{{ Number(wallet?.balance)?.toFixed(wallet?.currency?.places) }}</div>
          <div>{{ wallet?.currency?.name }}</div>
          <img :src="getIconByTicker(wallet?.currency?.ticker)" class="currency-icon" alt="" />
        </div>
      </div>

      <div v-for="freeBet in freeBets" :key="freeBet.id" class="free-bet">
        <div class="free-bet-header">
          <div>{{ t("casino.free_bet") }}</div>
          <div>{{ freeBet.amount }}</div>
          <img :src="getIconByTicker(freeBet?.currency?.ticker)" class="currency-icon" alt="" />
        </div>
        <div class="free-bet-description">
          <span>{{ t("casino.expired_at") }}&nbsp;</span>
          <span>{{ moment(freeBet.expiredAt).format("DD/MM/YYYY") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wallet-container {
  position: relative;
}

.currency-icon {
  margin-left: 9px;
  width: 13px;
  height: 13px;
}
.user-wallet-section {
  display: flex;
  height: 40px;

  .user-balance-section {
    background: var(--be-big-card-bg);
    padding: 1px 0px 2px 8px;
    width: 180px;
    cursor: pointer;

    &.left-rounded {
      border-radius: 8px 0 0 8px;
    }

    @media screen and (max-width: 500px) {
      &.left-rounded {
        border-radius: 6px;
      }
    }

    .user-name {
      color: var(--be-secondary-text-color);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 3px;
      font-family: Rubik;
    }
    .user-balance {
      display: flex;
      gap: 0px 5px;
      color: var(--be-primary-text-color);
      font-size: 14px;
      justify-content: space-between;
      margin-right: 10px;
      font-size: 14px;
      font-family: Rubik-med;
    }
  }

  .user-section {
    cursor: pointer;
    border-radius: 4px 0 0 4px;
    background: var(--be-secondary-btn);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }

  .wallet-section {
    cursor: pointer;
    border-radius: 0px 4px 4px 0px;
    background: linear-gradient(90deg, #3886ec 0%, #544fe5 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  }
}

.user_wallet-drop-down {
  border-radius: 8px;
  position: absolute;
  background: #0d0c2c;
  top: 50px;
  padding: 8px;
  font-size: 12px;

  width: 100%;
  .wallet-drop-item {
    display: flex;
    padding: 10px;
    border-radius: 4px;
    justify-content: space-between;
    cursor: pointer;
    opacity: 0.5;

    &.item-active {
      background: #1a1a3a;
      opacity: 1;
    }

    .item-right {
      display: flex;
      gap: 0 5px;
    }
  }

  .free-bet {
    border: 1px solid white;
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
    opacity: 0.7;
  }

  .free-bet-header {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 0 5px;
    margin-bottom: 5px;
  }

  .free-bet-description {
    font-size: 10px;
    font-weight: normal;
    text-align: right;
  }
}
</style>
