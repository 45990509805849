<script setup>
import { computed, ref } from "vue";
import { useBetStore } from "/store";
import BetModalSection from "./BetModalSection.vue";
import { t } from "@/app/composables/useI18n";
import { useAuth } from "@/user-account/composables/useAuth";
import { useReferAndEarn } from "@/app/composables/useReferAndEarn.js";

const { referAndEarn } = useReferAndEarn();

const store = useBetStore();

const show = computed(() => store.betModal);

const baseSections = ref([
  { value: "wallet", title: t("account.wallet"), icon: "wallet" },
  { value: "bet", title: `${t("sportsbook.mybets")}`, icon: "my-bets" },
  { value: "account", title: `${t("finance.account")}`, icon: "my-account" },
  { value: "referAndEarn", title: t("general.refer_earn"), icon: "refer-and-earn" },
]);
const sections = computed(() => {
  return baseSections.value.filter((section) => {
    return referAndEarn.value.enabled ? true : section.value !== "referAndEarn";
  });
});

function toggleSection(section) {
  store.toggleSection(section.value);
}

const { logout } = useAuth();

function handleLogout() {
  store.handleShowBetModal(false);
  logout();
}
</script>

<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click="() => store.handleShowBetModal(false)">
      <div class="modal-container" @click.stop>
        <BetModalSection
          v-for="section in sections"
          :key="section.value"
          :section="section"
          @toggle-section="toggleSection"
        />

        <div class="logout" @click="handleLogout">
          <img src="/assets/images/bets/account-details/logout.svg" alt="" />
          <div>{{ t("account.logout") }}</div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
.logout {
  display: flex;
  gap: 0 8px;
  padding: 16px;
  cursor: pointer;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #cdcdcd;
  color: white;
  line-height: 0px;
  margin: 10px 0 20px;
}
h2 span {
  background: var(--be-big-card-bg);
  font-size: 14px;
  padding: 0 16px;
}
.modal-mask {
  position: fixed;
  z-index: 100;
  top: 55px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(13, 12, 44, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 450px;
  margin-left: auto;
  // padding: 20px 20px;
  background: var(--be-bg);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
  height: calc(100vh - 73px);
  overflow: auto;
  padding-bottom: 20px;
}
@media screen and (max-width: 500px) {
  .modal-container {
    width: 100%;
    height: calc(100vh - 105px);
    padding-bottom: 85px;
  }
}
</style>
