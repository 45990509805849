<script setup>
import { ref, watch } from "vue";
import { useI18n } from "@/app/composables/useI18n";
import BaseButton from "@/app/components/ui/BaseButton.vue";
import BaseTextField from "@/app/components/ui/BaseTextField.vue";
import { rules as firstNameRules } from "@/user-account/utils/input-rules/first-name";
import { rules as lastNameRules } from "@/user-account/utils/input-rules/last-name";
import { rules as dateOfBirthRules } from "@/user-account/utils/input-rules/dob";
import { rules as emailRules } from "@/user-account/utils/input-rules/email";

const props = defineProps({
  regions: {
    type: Array,
    required: true,
  },
  personalData: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["submit"]);

const { t } = useI18n();

const inputs = ref([
  {
    name: "email",
    value: props.personalData?.email || "",
    type: "email",
    label: t("kyc.email"),
    placeholder: t("kyc.email"),
    rules: emailRules,
    clearable: false,
    error: "",
  },
  {
    name: "firstName",
    value: props.personalData?.firstName || "",
    type: "text",
    label: t("kyc.first_name"),
    placeholder: t("kyc.first_name"),
    rules: firstNameRules,
    clearable: false,
    error: "",
  },
  {
    name: "lastName",
    value: props.personalData?.lastName || "",
    type: "text",
    label: t("kyc.last_name"),
    placeholder: t("kyc.last_name"),
    rules: lastNameRules,
    clearable: false,
    error: "",
  },
  {
    name: "dateOfBirth",
    value: props.personalData?.dateOfBirth || "",
    type: "date",
    label: t("kyc.date_of_birth"),
    placeholder: t("kyc.date_of_birth"),
    rules: dateOfBirthRules,
    clearable: false,
    error: "",
  },
]);

watch(() => props.personalData, (value) => {
  inputs.value[0].value = value.email;
  inputs.value[1].value = value.firstName;
  inputs.value[2].value = value.lastName;
  inputs.value[3].value = value.dateOfBirth;
});
</script>

<template>
  <form @submit.prevent="emit('submit', inputs)">
    <div class="form-fields">
      <BaseTextField
        :model-value="inputs[0].value"
        :rules="inputs[0].rules"
        :label="inputs[0].label"
        :placeholder="inputs[0].placeholder"
        :type="inputs[0].type"
        :clearable="inputs[0].clearable"
        :icon-name="inputs[0].iconName"
        :error="inputs[0].error"
        @update:model-value="inputs[0].value = $event"
        @error="inputs[0].error = $event"
      />

      <BaseTextField
        :model-value="inputs[1].value"
        :rules="inputs[1].rules"
        :label="inputs[1].label"
        :placeholder="inputs[1].placeholder"
        :type="inputs[1].type"
        :clearable="inputs[1].clearable"
        :icon-name="inputs[1].iconName"
        :error="inputs[1].error"
        @update:model-value="inputs[1].value = $event"
        @error="inputs[1].error = $event"
      />

      <BaseTextField
        :model-value="inputs[2].value"
        :rules="inputs[2].rules"
        :label="inputs[2].label"
        :placeholder="inputs[2].placeholder"
        :type="inputs[2].type"
        :clearable="inputs[2].clearable"
        :icon-name="inputs[2].iconName"
        :error="inputs[2].error"
        @update:model-value="inputs[2].value = $event"
        @error="inputs[2].error = $event"
      />

      <BaseTextField
        :model-value="inputs[3].value"
        :rules="inputs[3].rules"
        :label="inputs[3].label"
        :placeholder="inputs[3].placeholder"
        :type="inputs[3].type"
        :clearable="inputs[3].clearable"
        :icon-name="inputs[3].iconName"
        :error="inputs[3].error"
        @update:model-value="inputs[3].value = $event"
        @error="inputs[3].error = $event"
      />
    </div>

    <BaseButton class="submit alight wide high" type="submit">
      {{ t("kyc.сlick_to_complete") }}
    </BaseButton>
  </form>
</template>

<style lang="scss" scoped>
.form-fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}
</style>
